.app {
  background-color: var(--primary-color);
  font-family: var(--font-base);
}

.app__whitebg {
  background-color: var(--white-color);
}

.app__primarybg {
  background-color: var(--primary-color);
}

.app__container {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: row;
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__wrapper {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 4rem 2rem;

  @media screen and (max-width: 450px) {
    padding: 4rem 1rem 2rem;
  }
}


.app__footer-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 1rem 0;

  transition: all 0.3s ease-in-out;

  a {
    width: 35px;
    height: 35px;

    svg {
      width: 35px;
      height: 35px;
      color: var(--gray-color);
    }

    &:hover {
      //background-color: var(--secondary-color);
      //border-color: var(--secondary-color);
      //cursor: pointer;
      svg {
        color: var(--secondary-color);
      }
    }
  }

}

.copyright {
  width: 100%;
  padding: 2rem 0 0;
  //padding: 0;

  //width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  //margin: 1rem 0;

  //
  //display: flex;
  //flex-direction: column;
  //justify-content: flex-end;
  //align-items: flex-end;

  p {
    text-transform: uppercase;
    color: var(--black-color);
  }
}

h2 {
  color: #1D0162 ;
  font-weight: bold;
  /*text-align: left;*/
  /*background-color: #2b6cb0 ;*/
}

.head-text {
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: #1D0162;

  span {
    color: var(--secondary-color);
  }

  @media screen and (min-width: 2000px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 2rem;
  }
}

.p-text {
  font-size: 0.8rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}

.profile-text {
  //font-size: 0.8rem;
  text-align: center;
  color: var(--gray-color);
  line-height: 1.5;

  //display: flex;
  //justify-content: center;
  //align-items: flex-start;
  //flex-wrap: wrap;
  margin-top: 2rem;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}

.p2-text {
  font-size: 0.8rem;
  text-align: left;
  //color: var(--gray-color);
  line-height: 1.5;
  white-space: nowrap !important;
  background-image: linear-gradient(90deg, rgba(131, 58, 180, .9) 0%, rgba(253, 29, 29, .9) 100%) !important;
  color: transparent !important;
  -webkit-background-clip: text !important;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}



.bold-text {
  font-size: 1rem;
  font-weight: 800;
  color: var(--black-color);
  text-align: left;

  @media screen and (min-width: 2000px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 0.9rem;
  }
}

.app__social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;

  padding: 1rem;

  div {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--white-color);
    margin: 0.25rem 0;
    border: 1px solid var(--lightGray-color);

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease-in-out;

    svg {
      width: 15px;
      height: 15px;
      color: var(--gray-color);
    }

    &:hover {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);
      cursor: pointer;
      svg {
        color: var(--white-color);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 70px;
      height: 70px;

      margin: 0.5rem 0;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}


.social-small-screen {
  //margin-top: 1rem;
    //margin: 1.25rem 0;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 1rem 0;

  //margin-left: 2rem;
  //  display: flex;
  //  justify-content: flex-start;
  //  align-items: center;

    transition: all 0.3s ease-in-out;

  a {
    width: 35px;
    height: 35px;

    svg {
      width: 35px;
      height: 35px;
      color: var(--gray-color);
    }

    &:hover {
      //background-color: var(--secondary-color);
      //border-color: var(--secondary-color);
      //cursor: pointer;
      svg {
        color: var(--secondary-color);
      }
    }
  }

}

.app__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 1rem;

  .app__navigation-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #cbcbcb;
    margin: 0.5rem;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: var(--secondary-color);
    }

    @media screen and (min-width: 2000px) {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (min-width: 501px) {
  .app__footer-social {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .app__navigation,
  .app__social {
    display: none;
  }

  .social-small-screen {
    //display: none;
  }

  .copyright {
    //padding: 2rem;
  }
}